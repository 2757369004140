export enum TargetType {
  api = "api",
  folder = "folder",
  websocket = "websocket",
  grpc = "grpc",
  doc = "doc",
  socket = "socket", // tcp客户端
  socket_method = "socket_method",
  sse = "sse",
}

export const BASE_INFO_ANCHOR = {
  key: "request-base-info",
  href: "#request-base-info",
  title: "content_navigation.info",
};

export const BASE_DETAIL_ANCHOR = {
  key: "request-base-desc",
  href: "#request-base-desc",
  title: "content_navigation.description",
};

export const BASE_DETAIL_GRPC =  {
  key: "response-method-parameter",
  href: "#response-method-parameter",
  title: "content_navigation.grpc_method",
}

export const BASE_FOLDER_DETAIL_ANCHOR = {
  key: "request-folder-description",
  href: "#request-folder-description",
  title: "content_navigation.folder_desc",
};

export const BASE_REQUEST_PARAMS_ANCHOR = {
  key: "request-query-parameter",
  href: "#request-query-parameter",
  title: "content_navigation.request",
};

export const BASE_METHOD_REQUEST_PARAMS_ANCHOR = {
  key: "request-query-parameter",
  href: "#request-query-parameter",
  title: "content_navigation.tcp_request",
};

export const BASE_MOCK_PARAMS_ANCHOR = {
  key: "request-mock-parameter",
  href: "#request-mock-parameter",
  title: "content_navigation.mock_url",
};

export const BASE_RESPONSE_EXAMPLE_ANCHOR = {
  key: "response-example-parameter",
  href: "#response-example-parameter",
  title: "content_navigation.response",
};

export const BASE_REQUEST_FOLDER_ANCHOR = {
  key: "request-folder-parameter",
  href: "#request-folder-parameter",
  title: "content_navigation.folder_param",
};

export const BASE_REQUEST_SETTING_ANCHOR = {
  key: "request-setting-parameter",
  href: "#request-setting-parameter",
  title: "content_navigation.settings",
};

export const BASE_REQUEST_FUNC_ANCHOR = {
  key: "request-func-parameter",
  href: "#request-func-parameter",
  title: "content_navigation.request_function",
};

export const BASE_RESPONSE_FUNC_ANCHOR = {
  key: "response-func-parameter",
  href: "#response-func-parameter",
  title: "content_navigation.response_function",
};

export const TARGET_TYPE_ANCHOR_MAP = {
  api: [
    BASE_INFO_ANCHOR,
    // BASE_DETAIL_ANCHOR,
    BASE_REQUEST_PARAMS_ANCHOR,
    {
      key: "response-example-parameter",
      href: "#response-example-parameter",
      title: "content_navigation.response",
    },
    BASE_MOCK_PARAMS_ANCHOR,
  ],
  folder: [
    BASE_INFO_ANCHOR,
    // BASE_FOLDER_DETAIL_ANCHOR,
    {
      key: "request-folder-parameter",
      href: "#request-folder-parameter",
      title: "content_navigation.folder_param",
    },
  ],
  websocket: [
    BASE_INFO_ANCHOR,
    // BASE_DETAIL_ANCHOR,
    BASE_REQUEST_PARAMS_ANCHOR,
    {
      key: "request-setting-parameter",
      href: "#request-setting-parameter",
      title: "content_navigation.settings",
    },
  ],
  socket: [
    BASE_INFO_ANCHOR,
    // BASE_DETAIL_ANCHOR,
  ],
  socket_method: [
    BASE_INFO_ANCHOR,
    // BASE_DETAIL_ANCHOR,
    BASE_METHOD_REQUEST_PARAMS_ANCHOR,
    {
      key: "response-query-parameter",
      href: "#response-query-parameter",
      title: "content_navigation.response",
    },
    {
      key: "request-func-parameter",
      href: "#request-func-parameter",
      title: "content_navigation.request_function",
    },
    {
      key: "response-func-parameter",
      href: "#response-func-parameter",
      title: "content_navigation.response_function",
    },
  ],
  grpc: [
    BASE_INFO_ANCHOR,
    BASE_DETAIL_ANCHOR,
    {
      key: "response-method-parameter",
      href: "#response-method-parameter",
      title: "content_navigation.grpc_method",
    },
  ],
  doc: [
    // 标题
  ],
};

export enum INTERFACE_FIELDS_ENUM {
  text = 1,
  number,
  radio,
  checkbox,
  date,
  link,
  email,
}
