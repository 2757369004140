import { createFromIconfontCN } from "@ant-design/icons";
import dayjs from "dayjs";
import { history, getLocale } from "umi";
import {
  TargetType,
  TARGET_TYPE_ANCHOR_MAP,
  BASE_DETAIL_GRPC,
  BASE_REQUEST_PARAMS_ANCHOR,
  BASE_INFO_ANCHOR,
  BASE_DETAIL_ANCHOR,
  BASE_FOLDER_DETAIL_ANCHOR,
  BASE_RESPONSE_EXAMPLE_ANCHOR,
  BASE_MOCK_PARAMS_ANCHOR,
  BASE_REQUEST_FOLDER_ANCHOR,
  BASE_REQUEST_SETTING_ANCHOR,
  BASE_METHOD_REQUEST_PARAMS_ANCHOR,
  BASE_REQUEST_FUNC_ANCHOR,
  BASE_RESPONSE_FUNC_ANCHOR,
} from '@/types/docV8';
import { getHIdValue } from '@/components/utils';
import ATools from 'apipost-tools';
import { Message } from 'adesign-react';
import { cloneDeep, size } from "lodash";

/**
 * 基于JSON序列化进行对象克隆
 */
export const jsonClone = (source: any): any => {
  return JSON.parse(JSON.stringify(source));
};

/**
 * 时间戳转换，如果传入的不是number类型，则返回原值
 */
export const convertTimestampToDateString = (
  timestamp: number | any,
  pattern: string = "YYYY-MM-DD HH:mm:ss",
): string | any => {
  if (typeof timestamp !== "number") {
    return timestamp;
  }
  return dayjs(timestamp).format(pattern);
};

//获取url链接中的参数
export function getQueryVariable(variable: string) {
  if (typeof window === "undefined") {
    return null;
  }

  const url = window.location.search;
  const reg = new RegExp(`(^|&)${variable}=([^&]*)(&|$)`);
  const result = url.slice(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
}

// 跳转到新的页面，支持在新窗口打开；
export function linkToUrl(url: string, target: string = "", isReplace?: boolean = false, state?: any) {
  if (typeof window === "undefined" || !url) {
    return;
  }
  let isCurDomain = /^\/[^\/]/.test(url);

  if (target) {
    window.open(url, target);
  } else {
    if (isCurDomain || url === "/") {
      // 如果跳转的页面，是当前页面，则直接return
      if (history?.location?.pathname === url) {
        return;
      }
      if (isReplace) {
        if (state) {
          history.push(url, state);
        } else {
          window.location.replace(url);
        }
      } else {
        history.push(url);
      }
    } else {
      location.href = url;
    }
  }
}

export const IconFont = createFromIconfontCN({
  scriptUrl: ["//at.alicdn.com/t/font_2798674_0mwvhzhphvce.css"],
});

export const IconFontV8 = createFromIconfontCN({
  scriptUrl: ["//at.alicdn.com/t/c/font_4385393_p257z50glp.js"],
});


/**
 * 编辑器内容格式化
 */
export const EditFormat = (value: any) => {
  return ATools.beautifyRaw(value);
};

// 复制文本到剪切板
export const copyStringToClipboard = (str: string, showMessage: Boolean = true) => {
  const clipboardObj = navigator.clipboard;
  const language = getLocale();
  const successTip = language === 'zh-CN' ? '复制成功' : 'Copy Successful'
  const failTip = language === 'zh-CN' ? '复制失败' : 'Copy Failed'
  // 不支持Clipboard对象直接报错
  if (clipboardObj) {
    // 读取内容到剪贴板
    clipboardObj.writeText(str).then(
      () => {
        showMessage && Message('success', successTip, 0.8);
      },
      () => {
        try {
          const textarea = document.createElement('textarea');
          document.body.appendChild(textarea);
          // 隐藏此输入框
          textarea.style.position = 'fixed';
          textarea.style.clip = 'rect(0 0 0 0)';
          textarea.style.top = '10px';
          // 赋值
          textarea.value = str;
          // 选中
          textarea.select();
          // 复制
          document.execCommand('copy', true);
          // 移除输入框
          document.body.removeChild(textarea);
        } catch (error) {
          showMessage && Message('error', failTip);
        }
      }
    );
  } else {
    try {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = 'fixed';
      textarea.style.clip = 'rect(0 0 0 0)';
      textarea.style.top = '10px';
      // 赋值
      textarea.value = str;
      // 选中
      textarea.select();
      // 复制
      document.execCommand('copy', true);
      // 移除输入框
      document.body.removeChild(textarea);
      if (str) {
        showMessage && Message('success', successTip, 0.8);
      }
    } catch (error) {
      showMessage && Message('error', failTip);
    }
  }
};

export const getMarked = (md: string | undefined) => {
  if (!md) return ''
  marked?.setOptions({
    renderer: new marked.Renderer(),
    highlight: function (code) {
      // 为页面所有pre code 进行高亮
      return hljs.initHighlighting(code);
    },
    breaks: true,
    sanitize: true
  });
  return marked(md).replace(/<pre>/g, "<pre class='hljs'>");
}

// 获取到salt， 用于获取token
export const getSaltCode = () => {
  const pathname = window.location.pathname
  const regex = /\/([\w-]+)$/;
  const match = pathname.match(regex);
  const result = match ? match[1] : null;
  return result;

}

export const array2Tree = (items: any[], idName: string = 'target_id', pidName: string = 'parent_id'): any[] => {
  try {
    const result=[];
    const itemMap : any={};
    for (const item of items) {
      const id=item[idName];
      const pid=item[pidName];
      if(!id || id == undefined){
        continue;
      }
      if(!itemMap.hasOwnProperty(id)){
        itemMap[id]={
          children: [],
        }
      }

      itemMap[id]={
        ...item,
        children: itemMap[id]['children']
      }

      const treeItem = itemMap[id];
      if(pid == 0 || pid == undefined){
        result.push(treeItem);
      }else{
        if(!itemMap.hasOwnProperty(pid)){
          itemMap[pid]={
            children:[],
          }
        }
        itemMap[pid].children.push(treeItem);
      }
    }
    return result;
  } catch (error) {
    return [];
  }
}

export const getTargetAnchorItems = (data: { [key: string]: any; target_type: TargetType }) => {
  const items = [BASE_INFO_ANCHOR];
  switch (data?.target_type) {
    case TargetType.api:
      items.push(BASE_REQUEST_PARAMS_ANCHOR, BASE_RESPONSE_EXAMPLE_ANCHOR);
      if (data?.mock_server_enable === 1) {
        items.push(BASE_MOCK_PARAMS_ANCHOR);
      }
      break;
    case TargetType.sse:
      items.push(BASE_REQUEST_PARAMS_ANCHOR, BASE_RESPONSE_EXAMPLE_ANCHOR);
      break;
    case TargetType.folder:
      items.push(BASE_REQUEST_FOLDER_ANCHOR);
      break;
    case TargetType.websocket:
      items.push(BASE_REQUEST_PARAMS_ANCHOR, BASE_REQUEST_SETTING_ANCHOR);
      break;
    case TargetType.socket_method:
      if ((size(data?.request?.body?.parameter) > 0 || data?.request?.body?.raw || data?.request?.body?.raw_schema?.items || Object.keys(data?.request?.body?.raw_schema?.properties || {})?.length) > 0) {
        items.push(BASE_METHOD_REQUEST_PARAMS_ANCHOR, BASE_RESPONSE_EXAMPLE_ANCHOR);
      }
      if (size(data?.request?.configs?.func?.request) > 0) {
        items.push(BASE_REQUEST_FUNC_ANCHOR);
      }
      if (size(data?.request?.configs?.func?.response) > 0) {
        items.push(BASE_RESPONSE_FUNC_ANCHOR);
      }
      break;
    case TargetType.grpc:
      items.push(BASE_DETAIL_GRPC);
      break;

    default:
      break;
  }
  return items;
};

export const getDocAPIAnchorItemsV7 = (data: any, boo: boolean) => {
  const items = cloneDeep(getTargetAnchorItems(data));
  if (boo) {
    const baseDescAnchor = data?.target_type === TargetType.folder ? BASE_FOLDER_DETAIL_ANCHOR : BASE_DETAIL_ANCHOR;
    const descItems: any = getHIdValue(data?.description);
    items.splice(1, 0, { ...baseDescAnchor }, ...descItems?.map((e: any) => ({ children: [e] })));
  }
  return items;
};
